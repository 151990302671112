import Mixins from "../../Mixins.js";
import Moment from "moment";

export default {
  name: "AddLoanAccount",
  components: {},
  mixins: [Mixins],
  data() {
    return {
      identity: {
        sp3Id: "",
      },
      identifier: {
        loanAccountParentNumber: "",
        loanAccountParentId: "",
        loanSp3Information: {
          sp3Subject: "",
          startDate: "",
          sp3DocNumber: "",
        },
        cifIdName: "",
        cifId: "",
        subLoan: {
          loanAccountIdSelectedForEdit: "",
        },
      },
      property: {
        animation: {
          step1: {
            addLoanAccount: {
              isLoading: false,
            },
          },
        },
      },
      dataForm: {
        step1: {
          rateFlatMount: 0,
          insuranceAccountNumber: "",
          notaryPublicAccountNumber: "",
          referalOfficeId: "",
          sp3Date: "",
          akadDate: "",
          endDate: "",
          timePeriod: 0,
          billDay: 0,
          isAutodebt: false,
          nominalLoan: 0,
          nominalPurchase: 0,
          rateFlat: 0,
          rateEffective: 0,
          rateMargin: 0,
          nominalMargin: 0,
          totalMargin: 0,
          totalSellingPrice: 0,
          nominalInstallment: 0,
          purposeLoan: "",
          isSmsNotification: false,
          isNeedCollateral: false,
          nominalCollateral: 0,
          collateralSharedPercent: 0,
          ujrahDropGroup: 0,
          ujrahDropGroupSub: 0,
          accountSection: 0,
          gracePeriode: 0,
          graceReason: "",
          feeAdmin: 0,
          feeMaterai: 0,
          feeNotaris: 0,
          feeInsuranceLife: 0,
          feeInsuranceLoss: 0,
          feeInsuranceVehicle: 0,
          blockedFund: 0,
          feeTotal: 0,
          collectorId: "",
          participateBank: 0,
          participateCif: 0,
          profitShareBank: 0,
          profitShareBankPercent: 0,
          profitShareBankNominal: 0,
          profitProjection: 0,
          isRelated: false,
          sp3Id: "",
          margin: false,
          loanId: "",
          savingAccountNumberDro: "",
          savingAccountNumberInstallment: "",
          loanCategoryId: "",
          loanCollateralId: "",
          loanGroupId: "",
          loanGroupParentId: "",
          accountOfficerCollectorId: "",
          accountOfficerHandleId: "",
          accountOfficerPromoId: "",
          loanAccountParentId: "",
          isLineFacility: false,
          cifNumber: 0,
          cifIdName: "",
          cifCategoryName: "",
          officeName: "",
          cifMobilePhoneNumber: 0,
          sp3Subject: "",
          totalParticipate: 0,
          isProfitShareBankPercentage: false,
          profitShareBankProjection: 0,
          journalRecord: {
            productCode: "",
            transactionCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN,
            journalRecordDetails: [],
          },
          loanAkadTypeAcronym: "",
          akadNumberNotaril: "",
          akadNumber: "",
        },
      },
      options: {
        step1: {
          referalOffice: [],
          savingAccountByCifId: [],
          kantorCabang: [],
          produkPembiayaan: [],
          produkPembiayaanByAkad: [],
          collector: [],
          accountOfficer: [],
          savingAccount: [],
          rekeningDroping: [],
          rekeningAngsuran: [],
          grupPendanaan: [],
          subLoanGroup: [],
          loanAkadType: [],
          loanChartOfAccountByLoanId: [],
          loanCollateral: [],
          loanGroupCategory: [],
          accountNotaris: [],
          titipanAsuransi: [],
        },
      },
    };
  },
  methods: {
    // showModalLoanSp3InformationLoanAccount() {
    //   this.$buefy.dialog.alert({
    //     title: "Data SP3",
    //     message: `
    //     <span><b>Tanggal SP3</b> : ${this.identifier.loanSp3Information.sp3Date}</span>
    //     <br />
    //     <span><b>Perihal</b> :  ${this.identifier.loanSp3Information.sp3Subject}</span>
    //     <br/>
    //     <span><b>No. Sp3</b> : ${this.identifier.loanSp3Information.sp3DocNumber}</span>
    //     `,
    //     type: "is-info",
    //     confirmText: "Tutup",
    //     canCancel: ["outside"],
    //   });
    // },
    async getSystemDate() {
      this.dataForm.step1.akadDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.step1.startDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.dataForm.step1.endDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    async addLoanAccount() {
      let marginCalculateId = this.dataForm.step1.margin == true ? "1" : "2";
      const payload = {
        akadNumber: this.dataForm.step1.akadNumber,
        akadDate: this.dataForm.step1.akadDate,
        startDate: this.dataForm.step1.startDate,
        endDate: this.dataForm.step1.endDate,
        accountSection: this.dataForm.step1.accountSection,
        timePeriod: this.dataForm.step1.timePeriod,
        nominalInstallment: this.dataForm.step1.nominalInstallment,
        billDay: this.dataForm.step1.billDay,
        isAutodebt: this.dataForm.step1.isAutodebt,
        nominalLoan: this.dataForm.step1.nominalLoan,
        rateFlat: this.dataForm.step1.rateFlat,
        rateEffective: this.dataForm.step1.rateEffective,
        purposeLoan: this.dataForm.step1.purposeLoan,
        collateralSharedPercent: this.dataForm.step1.collateralSharedPercent,
        isSmsNotification: this.dataForm.step1.isSmsNotification,
        isNeedCollateral: this.dataForm.step1.isNeedCollateral,
        nominalCollateral: this.dataForm.step1.nominalCollateral,
        ujrahDropGroup: this.dataForm.step1.ujrahDropGroup,
        ujrahDropGroupSub: this.dataForm.step1.ujrahDropGroupSub,
        feeAdmin: this.dataForm.step1.feeAdmin,
        feeMaterai: this.dataForm.step1.feeMaterai,
        feeNotaris: this.dataForm.step1.feeNotaris,
        gracePeriode: this.dataForm.step1.gracePeriode,
        graceReason: this.dataForm.step1.graceReason,
        feeInsuranceLife: this.dataForm.step1.feeInsuranceLife,
        feeInsuranceLoss: this.dataForm.step1.feeInsuranceLoss,
        feeInsuranceVehicle: this.dataForm.step1.feeInsuranceVehicle,
        blockedFund: this.dataForm.step1.blockedFund,
        feeTotal: this.dataForm.step1.feeTotal,
        accountOfficerCollectorId: this.dataForm.step1.accountOfficerCollectorId,
        participateBank: this.dataForm.step1.participateBank,
        participateCif: this.dataForm.step1.participateCif,
        profitShareBank: this.dataForm.step1.profitShareBank,
        profitProjection: this.dataForm.step1.profitProjection,
        isRelated: this.dataForm.step1.isRelated,
        sp3Id: this.identity.sp3Id,
        loanId: this.dataForm.step1.loanId,
        savingAccountNumberDro: this.dataForm.step1.savingAccountNumberDro,
        savingAccountNumberInstallment: this.dataForm.step1
          .savingAccountNumberInstallment,
        accountOfficerHandleId: this.dataForm.step1.accountOfficerHandleId,
        accountOfficerPromoId: this.dataForm.step1.accountOfficerPromoId,
        loanCollateralId: this.dataForm.step1.loanCollateralId,
        loanCategoryId: this.dataForm.step1.loanCategoryId,
        loanGroupId: this.dataForm.step1.loanGroupId,
        isLineFacility: this.dataForm.step1.isLineFacility,
        nominalPurchase: this.dataForm.step1.nominalPurchase,
        isProfitShareBankPercentage: this.dataForm.step1
          .isProfitShareBankPercentage,
        journalRecord: this.dataForm.step1.journalRecord,
        akadNumberNotaril: this.dataForm.step1.akadNumberNotaril,
        referalOfficeId: this.dataForm.step1.referalOfficeId,
        notaryPublicAccountNumber: this.dataForm.step1
          .notaryPublicAccountNumber,
        insuranceAccountNumber: this.dataForm.step1.insuranceAccountNumber,
        marginCalculateId: marginCalculateId,
      };
      console.log('payload => ', payload);
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.step1.addLoanAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "loan",
                reqUrl: "transaction-loan",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.$router.push(
                      `/loan/loan-account/${this.encryptBASE64(
                        resp.data.data.loanAccountId
                      )}?refId=$${this.encryptBASE64(
                        JSON.stringify({
                          loanAccountNumber: resp.data.data.loanAccountNumber,
                        })
                      )}`
                    );
                  });
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.step1.addLoanAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },

    changeProdukPembiayaanLoanAccount() {
      console.log("hello");
      this.getLoanChartOfAccountByLoanIdLoanAccount();
      this.options.step1.produkPembiayaanByAkad.map((i) => {
        if (i.value === this.dataForm.step1.loanId) {
          this.dataForm.step1.journalRecord.productCode = i.loanCode;
          console.log(i);
          switch (i.marginCalculate) {
            case "1":
              this.dataForm.step1.margin = true;
              this.dataForm.step1.rateEffective = 0;
              break;
            case "2":
              this.dataForm.step1.margin = false;
              this.dataForm.step1.rateFlat = 0;
              break;
            default:
              return;
              break;
          }
        }
      });
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym === "ITB"
      ) {
        this.calculateRateLoanAccount();
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccount();
        this.calculateProyeksiAngsuranLoanAccount();
      }
    },
    resetFormNominalPembiayaanLoanAccount() {
      this.dataForm.step1.participateBank = 0;
      this.dataForm.step1.participateCif = 0;
      this.dataForm.step1.rateFlat = 0;
      this.dataForm.step1.rateEffective = 0;
      this.dataForm.step1.profitShareBank = 0;
      this.dataForm.step1.nominalPurchase = 0;
      this.dataForm.step1.nominalLoan = 0;
      this.dataForm.step1.nominalInstallment = 0;
      this.dataForm.step1.rateMargin = 0;
      this.dataForm.step1.totalMargin = 0;
      this.dataForm.step1.totalSellingPrice = 0;
      this.dataForm.step1.nominalMargin = 0;
      this.dataForm.step1.totalParticipate = 0;
      this.dataForm.step1.profitProjection = 0;
      this.dataForm.step1.profitShareBankProjection = 0;
      this.dataForm.step1.isProfitShareBankPercentage = false;
    },
    changeNominalMarginLoanAccount() {
      console.log("running");
      if (
        this.dataForm.step1.nominalMargin &&
        this.dataForm.step1.nominalPurchase &&
        this.dataForm.step1.timePeriod
      ) {
        this.dataForm.step1.totalMargin =
          (this.dataForm.step1.nominalMargin /
            this.dataForm.step1.nominalPurchase) *
          100;
        this.dataForm.step1.rateMargin =
          (this.dataForm.step1.totalMargin / this.dataForm.step1.timePeriod) *
          12;
      }
      this.calculateRateLoanAccount();
    },
    calculateProyeksiAngsuranLoanAccount() {
      if (
        this.dataForm.step1.nominalLoan !== 0 &&
        this.dataForm.step1.timePeriod !== 0
      ) {
        this.dataForm.step1.nominalInstallment =
          this.dataForm.step1.nominalLoan /
          this.dataForm.step1.timePeriod;
        return;
      }
      this.dataForm.step1.nominalInstallment = 0;
    },
    calculateRateLoanAccount() {
      this.dataForm.step1.rateFlat = this.dataForm.step1.rateMargin;
      this.dataForm.step1.rateFlatMount = this.dataForm.step1.rateFlat / 12;
      if (
        this.dataForm.step1.margin === false &&
        this.dataForm.step1.nominalPurchase &&
        this.dataForm.step1.rateMargin
      ) {
        const payload = {
          rateFlat: this.dataForm.step1.rateMargin,
          principal: this.dataForm.step1.nominalPurchase,
        };
        this.convertToEffectiveLoanAccount(payload.rateFlat, payload.principal);
      }
      this.dataForm.step1.totalMargin = this.dataForm.step1.timePeriod
        ? (this.dataForm.step1.rateMargin / 12) * this.dataForm.step1.timePeriod
        : 0;
      this.dataForm.step1.nominalMargin = this.dataForm.step1.totalMargin
        ? (this.dataForm.step1.totalMargin *
            this.dataForm.step1.nominalPurchase) /
          100
        : 0;
      this.dataForm.step1.totalSellingPrice = this.dataForm.step1.nominalMargin
        ? this.dataForm.step1.nominalMargin +
          this.dataForm.step1.nominalPurchase
        : 0;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.totalSellingPrice;
      this.dataForm.step1.nominalInstallment =
        this.dataForm.step1.nominalLoan / this.dataForm.step1.timePeriod;
      // this.dataForm.step1.rateFlatMount = this.dataform.step1.rateFlat / 12;
    },
    async convertToEffectiveLoanAccount(rateFlat, principal) {
      const payload = {
        timePeriod: this.dataForm.step1.timePeriod,
        rateFlat: rateFlat,
        principal: principal,
      };
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "transaction-loan/convert-flat-to-efective",
          payload: payload,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step1.rateEffective = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    changeEndDateLoanAccount() {
      if (
        this.dataForm.step1.endDate !== "" &&
        this.dataForm.step1.startDate !== ""
      ) {
        var a = Moment(this.dataForm.step1.endDate);
        var b = Moment(this.dataForm.step1.startDate);
        this.dataForm.step1.timePeriod = a.diff(b, "months");
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
          this.dataForm.step1.loanAkadTypeAcronym === "ITB"
        ) {
          this.calculateRateLoanAccount();
        }
        if (
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
          this.dataForm.step1.loanAkadTypeAcronym ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
        ) {
          this.calculateRateEffectiveAnnuallyLoanAccount();
          this.calculateProyeksiAngsuranLoanAccount();
        }
      }
    },
    changeIsNeedCollateralLoanAccount(event) {
      if (event === false) {
        this.dataForm.step1.nominalCollateral = 0;
      }
    },
    changeStartDateLoanAccount() {
      if (this.dataForm.step1.timePeriod) {
        this.changeTimePeriodLoanAccount();
      }
    },
    changeTimePeriodLoanAccount() {
      if (this.dataForm.step1.startDate !== "") {
        var periode = !this.dataForm.step1.timePeriod
          ? 1
          : this.dataForm.step1.timePeriod;
        var calculate = Moment()
          .add(`${periode}`, "months")
          .format();
        this.dataForm.step1.endDate = calculate;
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MURABAHAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_IJARAH ||
        this.dataForm.step1.loanAkadTypeAcronym === "ITB"
      ) {
        this.calculateRateLoanAccount();
      }
      if (
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUDHARABAH ||
        this.dataForm.step1.loanAkadTypeAcronym ===
          this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_CONFIG_LOAN_AKAD_ACRYNM_MUSYARAKAH
      ) {
        this.calculateRateEffectiveAnnuallyLoanAccount();
        this.calculateProyeksiAngsuranLoanAccount();
      }
    },
    changeSelectTypeAkadLoanAccount() {
      this.resetFormNominalPembiayaanLoanAccount();
      const filterAkad = this.options.step1.produkPembiayaan.filter(
        (index) =>
          index.loanAkadTypeAcronym === this.dataForm.step1.loanAkadTypeAcronym
      );
      this.dataForm.step1.loanId = "";
      this.options.step1.produkPembiayaanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterAkad,
      ];
    },
    async getLoanSp3ById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl: "loan-sp3/" + this.identity.sp3Id,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.step1.cifIdName = resp.data.data.mcif
            ? resp.data.data.mcif.cifIdName
            : "";
          this.dataForm.step1.cifNumber = resp.data.data.mcif
            ? resp.data.data.mcif.cifNumber
            : "";
          this.dataForm.step1.cifCategoryName = resp.data.data.mcif
            ? resp.data.data.mcif.rcifCategoryByCifCategoryId
              ? resp.data.data.mcif.rcifCategoryByCifCategoryId.cifCategoryName
              : ""
            : "";
          this.identifier.cifId = resp.data.data.mcif
            ? resp.data.data.mcif.cifId
            : "";
          this.dataForm.step1.officeName = resp.data.data.mcif
            ? resp.data.data.mcif.moffice
              ? resp.data.data.mcif.moffice.officeName
              : ""
            : "";
          this.dataForm.step1.cifMobilePhoneNumber = resp.data.data.mcif
            ? resp.data.data.mcif.cifMobilePhoneNumber
            : "";
          // this.identifier.loanSp3Information.sp3Date = resp.data.data.sp3Date;
          // this.identifier.loanSp3Information.sp3Subject =
          //   resp.data.data.sp3Subject;
          // this.identifier.loanSp3Information.sp3DocNumber =
          //   resp.data.data.sp3DocNumber;
          this.getSavingAccountByCifId();
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListLoanAccount(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.routeToPageListLoanAccount(),
        });
      }
    },
    getReferenceLoan() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan/simple-list",
          params: {
            loanName: "",
            loanCode: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanId;
              const marginCalculate = index.loanMarginCalculateCode;
              const loanCode = index.loanCode;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              this.options.step1.produkPembiayaan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeAcronym,
                loanCode,
              });
            });
          }
        });
    },

    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.step1.referalOffice = resp.data.data;
          this.options.step1.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
        }
      } catch (error) {}
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListLoanAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/cif-id/" + this.identifier.cifId,
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.accountNumber;
            this.options.step1.savingAccountByCifId.push({ text, value });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListLoanAccount(),
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async getLoanChartOfAccountByLoanIdLoanAccount() {
      console.log("dababy");
      try {
        this.dataForm.step1.journalRecord.journalRecordDetails = [];
        const resp = await this.$store.dispatch({
          endPoint: "loan",
          type: "GET_DATA_FIND_BY",
          reqUrl: `loan-chart-of-account/loan-transaction/${
            this.dataForm.step1.loanId
          }/${
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_TRX_CODE_DROPPING_LOAN_ID
          }`,
        });
        if (resp.data.code === "SUCCESS") {
          console.log(resp);
          resp.data.data.map((i, index) => {
            console.log(index, i);
            const productLedgerId = i.loanChartOfAccountId;
            const chartOfAccountId = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountId
              : "";
            const chartOfAccountCode = i.chartOfAccount
              ? i.chartOfAccount.chartOfAccountCode
              : "";
            const label = i.productLedger
              ? i.productLedger.productLedgerName
              : "";
            const pairType = i.pairType;
            const positionMutation = i.mutationPosition;
            const labelInputComponent = i.chartOfAccount
              ? i.chartOfAccount.description
              : "";
            const pairPositionMutation = i.pairMutationPosition;
            const pairChartOfAccountId = i.pairChartOfAccount
              ? i.pairChartOfAccount.chartOfAccountId
              : "";
            const pairChartOfAccountCode = i.pairChartOfAccount
              ? i.pairChartOfAccount.pairChartOfAccountCode
              : "";
            const amount = 0;
            this.dataForm.step1.journalRecord.journalRecordDetails.push({
              productLedgerId,
              chartOfAccountId,
              chartOfAccountCode,
              pairChartOfAccountId,
              pairChartOfAccountCode,
              label,
              positionMutation,
              labelInputComponent,
              pairType,
              pairPositionMutation,
              amount,
              index: index,
            });
          });
        }
      } catch (error) {}
    },
    getReferenceLoanGroupCategory() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-group-category",
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((i) => {
              const text = i.loanGroupCategoryName;
              const value = i.loanGroupCategoryId;
              this.options.step1.loanGroupCategory.push({ text, value });
            });
          } else {
          }
        });
    },
    getReferenceLoanGroup() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "m-loan-group",
          params: {
            groupCode: "",
            groupName: "",
            accountNumber: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const text = `${i.groupCode} - ${i.groupName}`;
              const value = i.loanGroupId;
              this.options.step1.grupPendanaan.push({ text, value });
            });
          }
        });
    },
    // getReferenceCollector() {
    //   this.$store
    //     .dispatch("GET_REFERENCE_FROM_USER_MANAGEMENT", {
    //       url: "v2/user/active-user",
    //       params: {
    //         page: 0,
    //       },
    //     })
    //     .then((resp) => {
    //       if (resp.data.code === "SUCCESS") {
    //         resp.data.data.content.map((index) => {
    //           const text = `${index.profileSurename} - ${index.userNik}`;
    //           const value = index.userId;
    //           this.options.step1.collector.push({ text, value });
    //         });
    //       }
    //     });
    // },
    changeTipeBagiHasilBankLoanAccount() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitShareBank /
                this.dataForm.step1.profitProjection) *
              100;
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        case false:
          if (
            this.dataForm.step1.profitShareBank !== 0 &&
            this.dataForm.step1.profitProjection !== 0
          ) {
            this.dataForm.step1.profitShareBankProjection =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            this.dataForm.step1.profitShareBank =
              (this.dataForm.step1.profitProjection *
                this.dataForm.step1.profitShareBank) /
              100;
            return;
          }
          this.dataForm.step1.profitShareBank = 0;
          this.dataForm.step1.profitShareBankProjection = 0;
          break;

        default:
          break;
      }
    },
    changeProfitShareBankPercentLoanAccount() {
      switch (this.dataForm.step1.isProfitShareBankPercentage) {
        case true:
          this.dataForm.step1.profitShareBankProjection =
            (this.dataForm.step1.profitProjection *
              this.dataForm.step1.profitShareBank) /
            100;

          break;
        case false:
          this.dataForm.step1.profitShareBankProjection = this.dataForm.step1.profitShareBank;
          break;

        default:
          break;
      }
      this.calculateRateEffectiveAnnuallyLoanAccount();
      this.dataForm.step1.nominalLoan =
        this.dataForm.step1.participateBank +
        this.dataForm.step1.profitShareBankProjection;
      this.calculateProyeksiAngsuranLoanAccount();
    },
    calculateRateEffectiveAnnuallyLoanAccount() {
      if (
        this.dataForm.step1.profitShareBankProjection === 0 ||
        this.dataForm.step1.participateBank === 0 ||
        this.dataForm.step1.timePeriod === 0
      ) {
        this.dataForm.step1.rateEffective = 0;

        this.dataForm.step1.rateFlat = 0;
        return;
      } else {
        const calculateRate =
          (this.dataForm.step1.profitShareBankProjection /
            this.dataForm.step1.participateBank) *
          100;
        const payload = {
          rateFlat: (calculateRate / this.dataForm.step1.timePeriod) * 12,
          principal: this.dataForm.step1.participateBank,
        };
        this.convertToEffectiveLoanAccount(payload.rateFlat, payload.principal);
        this.dataForm.step1.rateFlat =
        (calculateRate / this.dataForm.step1.timePeriod) * 12;
        // this.dataForm.step1.rateEffective = 19;
      }
      // const calculateRate =
      //   (this.dataForm.step1.profitShareBankProjection /
      //     this.dataForm.step1.participateBank) *
      //   100;
      // if (this.dataForm.step1.margin === false) {
      //   const payload = {
      //     rateFlat: (calculateRate / this.dataForm.step1.timePeriod) * 12,
      //     principal: this.dataForm.step1.participateBank,
      //   };
      //   this.convertToEffectiveLoanAccount(payload.rateFlat, payload.principal);
      // }
      // this.dataForm.step1.rateFlat =
      //   (calculateRate / this.dataForm.step1.timePeriod) * 12;
      this.dataForm.step1.rateFlatMount = this.dataForm.step1.rateFlat / 12;
    },
    changeProfitProjectionLoanAccount() {
      if (this.dataForm.step1.isProfitShareBankPercentage === true) {
        this.changeProfitShareBankPercentLoanAccount();
        this.dataForm.step1.nominalLoan =
          this.dataForm.step1.participateBank +
          this.dataForm.step1.profitShareBankProjection;
        this.calculateProyeksiAngsuranLoanAccount();
      }
      this.calculateRateEffectiveAnnuallyLoanAccount();
    },
    async getReferenceAccountOfficer() {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "parameter",
            reqUrl: "account-officer/reference",
            payload: {
              jobTitle: "",
            },
          });
          console.log("AO => ", resp);
          if (resp.data.code === "SUCCESS") {
            resp.data.data.map((index) => {
              const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
              const value = index.accountOfficerId;
              this.options.step1.accountOfficer.push({ text, value });
            });
          }
        } catch (error) {}
    },
    getReferenceProvince() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          url: "area/province",
          params: {
            provinceName: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            resp.data.data.content.map((i) => {
              const value = i.provinceId;
              const text = i.provinceName;
              this.options.step4.vehicle.add.provinsi.push({ text, value });
              this.options.step4.vehicle.edit.provinsi.push({ text, value });
              this.options.step4.decree.add.provinsi.push({ text, value });
              this.options.step4.decree.edit.provinsi.push({ text, value });
            });
          }
        });
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "reference/loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeAcronym;
            this.options.step1.loanAkadType.push({
              text,
              value,
            });
          });
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    calculateParticipateLoanAccount(type) {
      this.dataForm.step1.totalParticipate =
        this.dataForm.step1.participateBank +
        this.dataForm.step1.participateCif;
      this.dataForm.step1.nominalLoan = this.dataForm.step1.participateBank;

      if (type === "WITH_CALCULATE_RATE") {
        this.calculateRateEffectiveAnnuallyLoanAccount();
        this.calculateProyeksiAngsuranLoanAccount();
      }
    },
    getRefferenceLoanCollateral() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          url: "loan-collateral",
          params: {
            collateralTypeId: "",
            page: 0,
          },
        })
        .then((resp) => {
          if (resp.data.code === "SUCCESS") {
            console.log(resp);
            resp.data.data.content.map((index) => {
              const text = index.description;
              const value = index.loanCollateralId;
              this.options.step1.loanCollateral.push({
                text,
                value,
              });
            });
          }
        });
    },
    routeToPageListLoanAccount() {
      this.$router.push("/loan/loan-account");
    },
    checkingMainId() {
      var query = this.$route.query.refId;
      if (!query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.routeToPageListLoanAccount();
        return;
      }
      this.identity.sp3Id = JSON.parse(this.decryptBASE64(query)).sp3Id;
    },
    async changeLoanGroupLoanAccount() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          reqUrl: "m-loan-group/sub",
          endPoint: "loan",
          payload: {
            groupId: this.dataForm.step1.loanGroupParentId,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.step1.subLoanGroup = [];
          this.dataForm.step1.loanGroupId = "";
          resp.data.data.map((data) => {
            const text = `${data.groupCode} - ${data.groupName}`;
            const value = data.loanGroupId;
            this.options.step1.subLoanGroup.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceAccountNotaris() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/by-chart-of-account-code",
          payload: {
            savingCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_SAVING_CODE_TITIPAN_NOTARIS,
            chartOfAccountCode: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_COA_CODE_TITIPAN_NOTARIS,
            allBranchesCanSee: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountNumber} - ${index.mcif.cifAliasName}`;
            const value = index.accountNumber;
            this.options.step1.accountNotaris.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceAccountIsurance() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/by-chart-of-account-code",
          payload: {
            savingCode: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_SAVING_CODE_TITIPAN_ASURANSI,
            chartOfAccountCode: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_COA_CODE_TITIPAN_ASURANSI,
            allBranchesCanSee: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountNumber} - ${index.mcif.cifAliasName}`;
            const value = index.accountNumber;
            this.options.step1.titipanAsuransi.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  beforeMount() {
    this.checkingMainId();
    this.getSystemDate();
  },
  mounted() {
    this.getReferenceAccountIsurance();
    this.getReferenceAccountNotaris();
    this.getLoanSp3ById();
    this.getReferenceLoan();
    this.getReferenceLoanAkadType();
    // this.getReferenceCollector();
    this.getReferenceAccountOfficer();
    this.getReferenceLoanGroupCategory();
    this.getReferenceLoanGroup();
    this.getRefferenceLoanCollateral();
    this.getReferenceOfficeReferal();
  },
};
