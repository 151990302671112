import AddLoanAccount from "./loan-account";
import Mixins from "../Mixins.js";

export default {
  name: "AddTLoanAccount",
  mixins: [Mixins],
  components: {
    AddLoanAccount,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_LOAN_ACCOUT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/loan-account");
    },
  },
};
