export default {
  data() {
    return {
      MIXINS_DATA: {
        identifier: {
          loanAccountId: this.$store.getters["get_identifier_loan_account_id"],
        },
      },
    };
  },
  computed: {
    mixin_data_identifier_loan_account_id() {
      return this.$store.getters["get_identifier_loan_account_id"];
    },
    mixin_data_options_loan_installment_loan_account() {
      return this.$store.getters["get_options_loan_account_loan_installment"];
    },
    mixin_data_options_loan_collateral_fixed_asset_loan_account() {
      return this.$store.getters[
        "get_options_loan_account_loan_collateral_fixed_asset"
      ];
    },
    mixin_data_options_loan_collateral_vehicle_loan_account() {
      return this.$store.getters[
        "get_options_loan_account_loan_collateral_vehicle"
      ];
    },
    mixin_data_options_loan_collateral_decree_loan_account() {
      return this.$store.getters[
        "get_options_loan_account_loan_collateral_decree"
      ];
    },
    mixin_data_options_loan_collateral_securities_loan_account() {
      return this.$store.getters[
        "get_options_loan_account_loan_collateral_securities"
      ];
    },
    mixin_data_options_loan_collateral_precious_metal_loan_account() {
      return this.$store.getters[
        "get_options_loan_account_loan_collateral_precious_metal"
      ];
    },
    mixin_data_options_loan_bi_code_loan_account() {
      return this.$store.getters["get_options_loan_account_loan_bi_code"];
    },
    mixin_data_options_loan_document_checklist_loan_account() {
      return this.$store.getters[
        "get_options_loan_account_loan_document_checklist"
      ];
    },
  },

  methods: {
    async MIXINS_METHOD_getRefferenceLoanAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "transaction-loan/findById/" +
              this.$store.getters["get_identifier_loan_account_id"],
          });
          if (resp.data.code === "SUCCESS") {
            var loanInstallment = [];
            var loanCollateralFixedAsset = [];
            var loanCollateralVehicle = [];
            var loanCollateralDecree = [];
            var loanCollateralSecurities = [];
            var loanCollateralPreciousMetal = [];
            var loanBiCode = [];
            var loanDocumentChecklist = [];
            loanInstallment = [{ value: "", text: "-- Pilih --" }];
            loanInstallment.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountId,
            });
            loanCollateralFixedAsset = [{ value: "", text: "-- Pilih --" }];
            loanCollateralFixedAsset.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
            });
            loanCollateralVehicle = [{ value: "", text: "-- Pilih --" }];
            loanCollateralVehicle.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
            });
            loanCollateralVehicle = [{ value: "", text: "-- Pilih --" }];
            loanCollateralVehicle.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
            });
            loanCollateralDecree = [{ value: "", text: "-- Pilih --" }];
            loanCollateralDecree.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
              cifIdName: resp.data.data.tloanSp3.mcif.cifIdName,
            });
            loanCollateralSecurities = [{ value: "", text: "-- Pilih --" }];
            loanCollateralSecurities.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
            });
            loanCollateralPreciousMetal = [{ value: "", text: "-- Pilih --" }];
            loanCollateralPreciousMetal.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
            });
            loanBiCode = [{ value: "", text: "-- Pilih --" }];
            loanBiCode.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountId,
              cifIdName: resp.data.data.tloanSp3.mcif.cifIdName,
            });
            loanDocumentChecklist = [{ value: "", text: "-- Pilih --" }];
            loanDocumentChecklist.push({
              text: `${resp.data.data.loanAccountNumber} - Kontrak Utama`,
              value: resp.data.data.loanAccountNumber,
              loanAccountNumber: resp.data.data.loanAccountNumber,
              loanAccountId: resp.data.data.loanAccountId,
            });
            this.$store
              .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
                params: {
                  loanAccountNumber: resp.data.data.loanAccountNumber,
                  cifIdName: "",
                  isSubContract: true,
                  page: 0,
                },
                url: "transaction-loan/simple-list",
              })
              .then((response) => {
                if (response.data.code === "SUCCESS") {
                  response.data.data.pageResponse.content.map((i) => {
                    loanInstallment.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountId,
                    });
                    loanCollateralFixedAsset.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountNumber,
                      loanAccountNumber: i.loanAccountNumber,
                      loanAccountId: i.loanAccountId,
                    });
                    loanCollateralVehicle.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountNumber,
                      loanAccountNumber: i.loanAccountNumber,
                      loanAccountId: i.loanAccountId,
                    });
                    loanCollateralDecree.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountNumber,
                      loanAccountNumber: i.loanAccountNumber,
                      loanAccountId: i.loanAccountId,
                    });
                    loanCollateralSecurities.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountNumber,
                      loanAccountNumber: i.loanAccountNumber,
                      loanAccountId: i.loanAccountId,
                      cifIdName: i.cifIdName,
                    });
                    loanCollateralPreciousMetal.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountNumber,
                      loanAccountNumber: i.loanAccountNumber,
                      loanAccountId: i.loanAccountId,
                      cifIdName: i.cifIdName,
                    });
                    loanBiCode.push({
                      loanAccountNumber: i.loanAccountNumber,
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountId,
                      cifIdName: i.cifIdName,
                    });
                    loanDocumentChecklist.push({
                      text: `${i.loanAccountNumber} - Sub Kontrak`,
                      value: i.loanAccountNumber,
                      loanAccountNumber: i.loanAccountNumber,
                      loanAccountId: i.loanAccountId,
                    });
                  });
                }
              })
              .finally(() => {
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_INSTALLMENT",
                  loanInstallment
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_FIXED_ASSET",
                  loanCollateralFixedAsset
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_VEHICLE",
                  loanCollateralVehicle
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_DECREE",
                  loanCollateralDecree
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_SECURITIES",
                  loanCollateralSecurities
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_COLLATERAL_PRECIOUS_METAL",
                  loanCollateralPreciousMetal
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_BI_CODE",
                  loanBiCode
                );
                this.$store.dispatch(
                  "SET_OPTIONS_LOAN_ACCOUNT_LOAN_DOCUMENT_CHECKLIST",
                  loanDocumentChecklist
                );
                setTimeout(() => {
                  console.log(this.MIXINS_DATA);
                }, 2000);
              });
          }
        } catch (error) {}
      }
    },
  },
};
